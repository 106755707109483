import React from 'react'
import { LazyLoadImage,trackWindowScroll } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import IconPlay from "../../images/prev/pic-2.jpg"

const Image = ( { image, scrollPosition } ) => (

        image ? <LazyLoadImage
            key={image.src}
            alt="Картинка загружается"
            src={image.src}
            threshold={150}
            placeholderSrc={IconPlay}
            scrollPosition={{x:window.scrollX,y:window.scrollY}}
            effect="blur"
            />
            : null

)

export default trackWindowScroll(Image)
